import React from 'react';
import HPPortfolio from './HPPortfolio';
import HPContact from './HPContact';
import Header from './Header';

// import OrangeBackgroundImg from '../../imgs/orange-background-img.svg';

function HomePage() {
    return (
        <>
            <div className='home-page'>
                {/* <img
                    src={OrangeBackgroundImg}
                    className='background-img'
                    alt='background-img'
                /> */}
                <div className='content'>
                    <Header />
                    <HPPortfolio />
                    <HPContact />
                </div>
            </div>
        </>
    );
}

export default HomePage;
