import React from 'react';

function BlogSite() {
    return (
        <>
            <p>blog site</p>
        </>
    );
}

export default BlogSite;
